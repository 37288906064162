<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <b-form @submit.prevent.stop="onSubmitForm">
        <div class="card-header">
          <h4 class="title-card-custom">Ubah Data Provinsi</h4>
        </div>
        <div class="card-body">
          <b-form-group
            id="provinsi-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Daerah"
            label-for="provinsi"
            label-class="required"

          >
            <b-form-select
              v-model="form.provinsi"
              :options="provinsis"
              class="mb-3"
              required
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="badan-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Bentuk Kelembagaan"
            label-for="badan"
            label-class="required"

          >
            <b-form-select v-model="form.badan" :options="badans" class="mb-3" required>
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="nomenklatur-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Nomenklatur Kelembagaan"
            label-for="nomenklatur"
            label-class="required"

          >
            <b-form-input
              id="nomenklatur"
              v-model="form.nomenklatur"
              type="text"
              placeholder="Nomenklatur"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="alamat-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Alamat Kantor"
            label-for="alamat"
            label-class="required"

          >
            <b-form-textarea
              id="alamat"
              v-model="form.alamat"
              placeholder="Alamat Kantor"
              rows="3"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="nama-pimpinan-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Nama Pimpinan"
            label-for="nama_pimpinan"
            label-class="required"

          >
            <b-form-input
              id="nama_pimpinan"
              v-model="form.nama_pimpinan"
              type="text"
              placeholder="Nama"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="no-telp-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="No. Telepon/Whatsapp Kantor"
            label-for="no_telp"
            label-class="required"
          >
            <b-form-input
              id="no_telp"
              v-model="form.no_telp"
              type="tel"
              placeholder="No. Telp."
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="email-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Email Kantor"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              placeholder="Email Kantor"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-7"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Perda Pembentukan"
            label-for="perda_pembentukan-7"
            label-class="required"
          >
            <b-row>
              <b-col cols="8">
                <b-form-input
                  id="perda_pembentukan"
                  v-model="form.perda_pembentukan"
                  type="text"
                  placeholder="Perda Pembentukan"
                  required
                  ></b-form-input>

                  <small class="form-text text-muted">Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)</small>
              </b-col>
              <b-col cols="4"
                ><b-form-file
                  ref="files"
                  placeholder="Pilih File"
                  drop-placeholder="Drop file di sini..."
                  accept=".pdf, .doc, .docx"
                  v-model="form.perda_pembentukan_path"
                  browse-text="Cari"
                  multiple
                  :file-name-formatter="formatNames"
                  @change="
                    uploadPerdaPembentukan($event);
                    validateFileTypeGeneral($event);
                    fileSizeChecker('10', $event);
                  "
                ></b-form-file>

                <small class="form-text text-muted" v-html="perda_pembentukan_link"></small>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            id="struktur-organisasi-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Struktur Organisasi"
            label-for="struktur-organisasi"
            label-class="required"
          >
            <b-row>
              <b-col cols="8">
                <b-form-input
                  id="struktur_organisasi"
                  v-model="form.struktur_organisasi"
                  type="text"
                  placeholder="Struktur Organisasi"
                  required
                ></b-form-input>

                <small class="form-text text-muted">Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)</small>
              </b-col>
              <b-col cols="4">
                <b-form-file
                  ref="file"
                  placeholder="Pilih File"
                  drop-placeholder="Drop file di sini..."
                  accept=".pdf, .doc, .docx"
                  v-model="form.struktur_organisasi_path"
                  browse-text="Cari"
                  multiple
                  :file-name-formatter="formatNames"
                  @change="
                    uploadStrukturOrg($event);
                    validateFileTypeGeneral($event);
                    fileSizeChecker('10', $event);
                  "
                ></b-form-file>

                <small class="form-text text-muted" v-html="struktur_organisasi_link"></small>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            id="nama-personil-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Nama Personil"
            label-for="nama-personil"
          >
            <b-row>
              <b-col cols="8">
                <b-form-input
                  id="nama_personil"
                  v-model="form.nama_personil"
                  type="text"
                  placeholder="Nama Personil"
                ></b-form-input>

                <small class="form-text text-muted">Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)</small>
              </b-col>
              <b-col cols="4">
                <b-form-file
                  ref="file"
                  placeholder="Pilih File"
                  drop-placeholder="Drop file di sini..."
                  accept=".pdf, .doc, .docx"
                  v-model="form.nama_personil_path"
                  browse-text="Cari"
                  multiple
                  :file-name-formatter="formatNames"
                  @change="
                    uploadNamaPersonil($event);
                    validateFileTypeGeneral($event);
                    fileSizeChecker('10', $event);
                  "
                ></b-form-file>

                <small class="form-text text-muted" v-html="nama_personil_link"></small>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            id="keterangan-group"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Keterangan"
            label-for="keterangan"
          >
            <b-form-textarea
              id="keterangan"
              v-model="form.keterangan"
              placeholder="Keterangan"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <button
                class="btn btn-secondary"
                type="reset"
                style="border-radius: 3px;"
                @click="$router.back()"
              >
                Batal
              </button>
              <button class="ml-2 btn btn-submit" type="submit">Kirim</button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = localStorage.getItem("token");
let id = null;
let getAttempt = 0;

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [fileSizeChecker, validateFileTypeGeneral],
  name: "UbahProvinsi",
  data() {
    return {
      form: {
        provinsi: null,
        badan: null,
        nomenklatur: "",
        alamat: "",
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan_path: null,
        perda_pembentukan_temp: null,
        perda_pembentukan: "",
        struktur_organisasi_path: null,
        struktur_organisasi_temp: null,
        struktur_organisasi: "",
        nama_personil_path: null,
        nama_personil_temp: null,
        nama_personil: "",
        keterangan: ""
      },
      new_id: null,
      show: true,
      provinsis: null,
      badans: null,
      perda_pembentukan_link: null,
      struktur_organisasi_link: null,
      nama_personil_link: null,
    };
  },
  mounted() {
    id = this.$route.params.id;

    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    }).then(() => {
      this.getRefProv()
      this.getRefBentuk()

        this.getDetailKelembagaanProvinsi(id);
    });

  },
  methods: {
    getRefBentuk(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getRefBentuk(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];

            if (detailMenu) {
              detailMenu.map(i => {
                return selectedOptions.push({
                  text: i.name,
                  value: i.id
                });
              });
            }

            this.badans = selectedOptions;
          }
        });
    },
    getRefProv(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/ref/provinsi?take=1000`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 5) {
            this.getRefProv(attempt);
          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];

            if (detailMenu) {
              detailMenu.map(i => {
                return selectedOptions.push({
                  text: i.name,
                  value: i.id
                });
              });
            }

            this.provinsis = selectedOptions;
          }

        });
    },
    getDetailKelembagaanProvinsi(id) {
      getAttempt++;

      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi/${id}`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.status == 'success') {
            let row = data.data;

            this.form.provinsi = row.provinsi_id;
            this.form.badan = row.bentuk_kelembagaan_id;
            this.form.nomenklatur = row.nomenklatur;
            this.form.alamat = row.alamat;
            this.form.nama_pimpinan = row.nama_pimpinan;
            this.form.no_telp = row.no_telp;
            this.form.email = row.email;
            this.form.perda_pembentukan = row.perda_pembentukan;
            this.form.perda_pembentukan_path = row.perda_pembentukan_path;
            // this.form.perda_pembentukan_temp = row.perda_pembentukan_path;
            this.perda_pembentukan_link = this.downloadUrls(row);
            this.form.struktur_organisasi = row.struktur_organisasi;
            this.form.struktur_organisasi_path = row.struktur_organisasi_path;
            // this.form.struktur_organisasi_temp = row.struktur_organisasi_path;
            this.struktur_organisasi_link = this.downloadStruktur(row);
            this.form.nama_personil = row.nama_personil;
            this.form.nama_personil_path = row.nama_personil_path;
            // this.form.nama_personil_temp = row.nama_personil_path;
            this.nama_personil_link = this.downloadPersonil(row);
            this.form.keterangan = row.keterangan;
          }
        })
        .catch(error => {
          if (error.status === 404 && getAttempt < 3) {
            this.getDetailKelembagaanProvinsi(id);
          } else {
            Swal.fire({
              title: "Kesalahan",
              text: "Data tidak ditemukan",
              type: "error",
              confirmButtonText: "Ok"
            }).then(() => {
              this.$router.push("/kelembagaan-provinsi");
            });
          }
        });
    },
    uploadPerdaPembentukan(event) {
      Swal.showLoading();

      this.form.perda_pembentukan_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of this.form.perda_pembentukan_path) {
        dataUpload.append("files", file, file.name);
      }

      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.perda_pembentukan_path = dataHasilUpload.data.join(";");
        });
    },
    uploadStrukturOrg(event) {
      Swal.showLoading();

      this.form.struktur_organisasi_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of this.form.struktur_organisasi_path) {
        dataUpload.append("files", file, file.name);
      }

      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.struktur_organisasi_path = dataHasilUpload.data.join(";");
        });
    },
    uploadNamaPersonil(event) {
      Swal.showLoading();

      this.form.nama_personil_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of this.form.nama_personil_path) {
        dataUpload.append("files", file, file.name);
      }

      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.nama_personil_path = dataHasilUpload.data.join(";");
        });
    },
    onSubmitForm() {
      let data = {
        provinsi_id: this.form.provinsi,
        bentuk_kelembagaan_id: this.form.badan,
        nomenklatur: this.form.nomenklatur,
        alamat: this.form.alamat,
        nama_pimpinan: this.form.nama_pimpinan,
        no_telp: this.form.no_telp,
        email: this.form.email,
        perda_pembentukan: this.form.perda_pembentukan,
        perda_pembentukan_path: this.form.perda_pembentukan_path,
        struktur_organisasi: this.form.struktur_organisasi,
        struktur_organisasi_path: this.form.struktur_organisasi_path,
        nama_personil: this.form.nama_personil,
        nama_personil_path: this.form.nama_personil_path,
        keterangan: this.form.keterangan
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi/${id}`),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(async data => {
              await Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambahkan",
                showConfirmButton: false,
                timer: 1500
              });
              window.history.go(-1);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal menambahkan data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    downloadUrls(rowData) {
      let paths = rowData.perda_pembentukan_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a><br/>`;
        });
      }

      return output;
    },
    downloadStruktur(rowData) {
      let paths = rowData.struktur_organisasi_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a><br/>`;
        });
      }

      return output;
    },
    downloadPersonil(rowData) {
      let paths = rowData.nama_personil_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a><br/>`;
        });
      }

      return output;
    },
  }
};
</script>

<style scoped>
.btn-submit {
  background: #18328d;
  border-radius: 3px;
  color: #ffffff;
}
.btn-submit:hover {
  background: #3851ab;
}
</style>
